import { useState, useEffect, useCallback } from 'react';

export default function useDarkColorSchemePreference() {
    const [prefersDarkMode, setPrefersDarkMode] = useState(false);
    const [hasUserPreference, setHasUserPreference] = useState(false);

    // Initialize theme
    useEffect(() => {
        if (typeof window === 'undefined') return;
        const userPreference = localStorage.getItem('userThemePreference');
        if (userPreference !== null) {
            setPrefersDarkMode(userPreference === 'dark');
            setHasUserPreference(true);
        } else {
            setPrefersDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
        }
    }, []);

    // Listen for system changes
    useEffect(() => {
        if (typeof window === 'undefined') return;
        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        const handleChange = (e) => {
            if (!hasUserPreference) {
                setPrefersDarkMode(e.matches);
            }
        };
        // Use addEventListener if available, otherwise fallback to addListener
        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener('change', handleChange);
        } else {
            mediaQuery.addListener(handleChange); // Deprecated but needed for compatibility
        }
        return () => {
            // Use removeEventListener if available, otherwise fallback to removeListener
            if (mediaQuery.removeEventListener) {
                mediaQuery.removeEventListener('change', handleChange);
            } else {
                mediaQuery.removeListener(handleChange);
            }
        };
    }, [hasUserPreference]);

    // Apply class and save preference
    useEffect(() => {
        if (typeof window === 'undefined') return;
        document.documentElement.classList.toggle('regard-color-scheme-preference', prefersDarkMode);
        // Also toggle on body for compatibility if needed, though documentElement is preferred
        document.body.classList.toggle('regard-color-scheme-preference', prefersDarkMode);
        if (hasUserPreference) {
            localStorage.setItem('userThemePreference', prefersDarkMode ? 'dark' : 'light');
        }
    }, [prefersDarkMode, hasUserPreference]);

    // Toggle function
    const toggleDarkMode = useCallback(() => {
        setPrefersDarkMode(prevMode => !prevMode);
        setHasUserPreference(true); // User has now expressed a preference
    }, []);

    return { prefersDarkMode, toggleDarkMode };
}