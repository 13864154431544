import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import {trackButtonClick} from '../util/trackButtonClick';

const InPageNavigation = () => {
  const { pathname: currentPath, hash: urlHash } = useLocation();
  const data = useStaticQuery(graphql`
    query getTableOfContents {
      allMdx {
        nodes {
          fields {
            slug
          }
          tableOfContents
        }
      }
    }
  `);

  /* This is a workaround as Graphql don't allow variables to be used in queries, making it hard to do a filtered query on pathname */
  const findHeadingsBelongingToPage = () => data.allMdx.nodes.find(content => content.fields.slug === currentPath);
  const headers = findHeadingsBelongingToPage()?.tableOfContents.items || [];
  const anchorIsActive = anchorUrl => decodeURIComponent(urlHash) === anchorUrl;
  return (
    <aside className="sb1ds-inpage-nav">
      <ul className="sb1ds-inpage-nav__list">
        {headers.map(heading =>
          <li className="sb1ds-inpage-nav__list-item" key={heading.url}>
            <Link
                to={heading.url}
                className={`sb1ds-inpage-nav__link ${anchorIsActive(heading.url) ? 'sb1ds-inpage-nav__link--active' : ''}`}
                onClick={() => trackButtonClick({action: 'in-page-link', text: heading.title})}
            >
              {heading.title}
            </Link>
          </li>
        )}
      </ul>
    </aside>
  );
};

export default InPageNavigation;
