import React from 'react';
import { Helmet } from 'react-helmet';
import useDarkColorSchemePreference from '../util/useDarkColorSchemePreference';

// Placeholder for trackButtonClick if not available
const trackButtonClick = (data) => console.log('Track Button Click:', data);

export default function DarkmodeButton() {
    const { prefersDarkMode, toggleDarkMode } = useDarkColorSchemePreference();
    const title = 'Aktiver darkmode';

    return (
        <>
            <Helmet>
                {/* Apply class directly to body using Helmet for SSR compatibility */}
                <body className={prefersDarkMode ? 'sb1ds-body regard-color-scheme-preference' : 'sb1ds-body'}/>
            </Helmet>
            {/* Use the new sb1ds-darkmode-button structure */}
            <div className={`sb1ds-darkmode-button ${prefersDarkMode ? 'is-dark' : ''}`}>
                {/* Light icon before the switch */}
                <img src="/img/light-mode-icon.svg"
                        className="sb1ds-darkmode-button__icon sb1ds-darkmode-button__icon--light"
                        alt="Ikon for lys modus" aria-hidden="true"/>
                <input
                    className="sb1ds-darkmode-button__switch"
                    type="checkbox"
                    id="darkmode-switch"
                    checked={prefersDarkMode}
                    onChange={() => {
                        toggleDarkMode();
                        trackButtonClick({ action: `darkmode-switch-${prefersDarkMode ? 'off' : 'on'}`, text: title });
                    }}
                />
                <label
                    className="sb1ds-darkmode-button__label"
                    htmlFor="darkmode-switch"
                >
                    {/* Label is now visually just the switch background/knob */}
                </label>
                {/* Dark icon after the switch */}
                <img src="/img/dark-mode-icon.svg"
                        className="sb1ds-darkmode-button__icon sb1ds-darkmode-button__icon--dark"
                        aria-hidden="true"/>
            </div>
        </>
    );
}